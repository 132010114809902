import React from 'react';
import { Typography } from '@material-ui/core';
import {
    compose,
    display,
    flexbox,
    palette,
    spacing,
    sizing,
    typography,
    positions,
} from '@material-ui/system';
import { Theme, TypographyVariant, styled } from '@material-ui/core/styles';

const defaultLetterSpacings: { [key: string]: string } = {
    '0.875rem': '0.01071rem',
    '1rem': '0.00938rem',
    '1.125rem': '0.008rem',
    '1.25rem': '0.0075rem',
    '1.5rem': '0rem',
    '2.125rem': '0.00735rem',
    '3rem': '0rem',
    '3.75rem': '-0.00833rem',
    '6rem': '-0.01562rem',
};

const defaultLineHeights: { [key: string]: number } = {
    '0.75rem': 1.5,
    '0.875rem': 1.4,
    '1rem': 1.4,
    '1.125rem': 1.3,
    '1.25rem': 1.3,
    '1.5rem': 1.3,
    '2rem': 1.2,
    '2.5rem': 1.2,
    '3rem': 1.1,
    '3.75rem': 1,
    '6rem': 1,
};

const getTextTransform = (
    uppercase: boolean | undefined,
    lowercase: boolean | undefined,
    capitalize: boolean | undefined,
) => {
    if (uppercase) {
        return 'uppercase';
    }
    if (lowercase) {
        return 'lowercase';
    }
    if (capitalize) {
        return 'capitalize';
    }

    return 'none';
};

const getSizeProperties = (
    theme: Theme,
    size: string,
    sizeSm?: string,
    sizeMd?: string,
    sizeLg?: string,
    lineHeight?: string | number,
    letterSpacing?: string | number,
) => {
    if (sizeLg || sizeMd || size) {
        return {
            fontSize: size,
            lineHeight: lineHeight || defaultLineHeights[size],
            letterSpacing: letterSpacing || defaultLetterSpacings[size],
            [theme.breakpoints.up('sm')]: {
                fontSize: sizeSm || size,
                lineHeight: lineHeight || defaultLineHeights[sizeSm || size],
                letterSpacing: letterSpacing || defaultLetterSpacings[sizeSm || size],
            },
            [theme.breakpoints.up(740)]: {
                fontSize: sizeMd || sizeSm || size,
                lineHeight: lineHeight || defaultLineHeights[sizeMd || sizeSm || size],
                letterSpacing: letterSpacing || defaultLetterSpacings[sizeMd || sizeSm || size],
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: sizeLg || sizeMd || sizeSm || size,
                lineHeight: lineHeight || defaultLineHeights[sizeLg || sizeMd || sizeSm || size],
                letterSpacing: letterSpacing || defaultLetterSpacings[sizeLg || sizeMd || sizeSm || size],
            },
        };
    }

    return {};
};

type TextProps = {
    className?: string,
    children: React.ReactNode;
    component?: React.ElementType;
    variant?: TypographyVariant;
    title?: string;
}

type stylesProps = TextProps & {
    theme: Theme,
    size?: string,
    sizeSm?: string,
    sizeMd?: string,
    sizeLg?: string,
    bold?: boolean,
    color?: string,
    variant?: 'inherit' | TypographyVariant | undefined,
    uppercase?: boolean,
    lowercase?: boolean,
    capitalize?: boolean,
    sentenceCase?: boolean,
    lineHeight?: string | number,
    letterSpacing?: string | number,
    textDecoration?: string,
    opacity?: number,
    italic?: boolean,
    whiteSpace?: string,
    fontFamily?: string;
    textShadow?: string;
    ellipsis?: boolean;
    textUnderlineOffset?: string;
};

const styles = ({
    theme,
    size,
    sizeSm,
    sizeMd,
    sizeLg,
    bold = false,
    color = theme.palette.primary.main,
    uppercase,
    lowercase,
    capitalize,
    sentenceCase,
    lineHeight,
    letterSpacing,
    textDecoration,
    opacity,
    italic,
    whiteSpace,
    fontFamily,
    textShadow,
    ellipsis,
    textUnderlineOffset,
}: stylesProps) => ({
    textRendering: 'optimizeLegibility',
    textSizeAdjust: 'none',
    fontWeight: bold ? 'bold' : 'normal',
    textTransform: getTextTransform(uppercase, lowercase, capitalize),
    color,
    lineHeight,
    letterSpacing,
    textDecoration,
    opacity,
    whiteSpace,
    textShadow,
    ...(sentenceCase && {
        '&::first-letter': {
            textTransform: 'uppercase',
        },
    }),
    ...(size && getSizeProperties(theme, size, sizeSm, sizeMd, sizeLg, lineHeight, letterSpacing)),
    ...(!size && {
        fontSize: 'inherit',
        lineHeight: 'inherit',
        letterSpacing: 'inherit',
    }),
    ...(italic && {
        fontStyle: 'italic',
    }),
    ...(ellipsis && {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }),
    fontFamily: fontFamily || 'fluro, serif',
    textUnderlineOffset,
});

const Text = ({ className, children, component = 'p', variant = 'body1', title }: TextProps) => (
    <Typography className={className} component={component} variant={variant} title={title}>{children}</Typography>
);

export default styled(Text)(compose(styles, display, flexbox, palette, spacing, sizing, typography, positions));
