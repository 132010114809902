export const { NODE_ENV } = process.env;

// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const APP_VERSION = '2.0.0';
export const API_BASE_URL = '/api';

export const FRONTEND_SERVER = process.env.REACT_APP_FRONTEND_SERVER || 'http://localhost:3000';
export const BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER || 'http://localhost:4000/';
export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL || `${BACKEND_SERVER}/graphql`;
export const port = 4444;

export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
export const AW_CONVERSION_ID = process.env.REACT_APP_AW_CONVERSION_ID;
export const GMAPS_API_KEY = process.env.REACT_APP_GMAPS_API_KEY || "";

export const IMAGE_SERVER = `https://${process.env.REACT_APP_IMAGE_SERVER}`;

export const LONDON_REGION_ID = "r222";
export const UK_REGION_ID = "r1111";

export const CONTENTFUL_SERVER = process.env.REACT_APP_CONTENTFUL_SERVER;

export const HEAP_API_KEY = process.env.REACT_APP_HEAP_API_KEY;
