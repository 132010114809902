import React, { useContext, useState } from 'react';
import { Dialog, DialogContent, Grid, IconButton } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import { ReactComponent as CloseIcon } from 'src/assets/images/home/CloseIcon.svg';
import ResultsPageBlurSmall from 'src/assets/images/home/results-page-blur-small.png';
import ResultsPageBlurMedium from 'src/assets/images/home/results-page-blur-medium.png';
import ResultsPageBlurLarge from 'src/assets/images/home/results-page-blur-large.png';
import track from 'src/libs/analytics';
import { AppContext } from 'src/store/store';
import { toggleListOverlay } from 'src/store/actions';

import Text from 'src/shared/components/Text/Text';
import { Instructions } from './components/Instructions';
import { ListingForm } from './components/ListingForm';
import { Close } from './components/Close';
import { ProviderQuote } from './components/ProviderQuote';

const TheDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        paddingTop: "3rem",
    },
    '& .MuiBackdrop-root': {
        backgroundImage: `url(${ResultsPageBlurSmall})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        filter: 'brightness(0.5)',
        [theme.breakpoints.up('md')]: {
            backgroundImage: `url(${ResultsPageBlurMedium})`,
        },
        [theme.breakpoints.up('lg')]: {
            backgroundImage: `url(${ResultsPageBlurLarge})`,
        },
    },
    "& .MuiDialog-paper": {
        overflow: "visible",
        border: "2px solid #F52727",
        borderRadius: 0,
        marginTop: "3rem"
    },
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '700px',
    },
}));

const CloseButton = styled(IconButton)({
    position: 'absolute',
    top: '0',
    right: '0',
    transform: 'translate(50%, -50%)',
});

export enum FormStatus {
    UNSENT = 'UNSENT',
    SUCCESS = 'SUCCESS',
}

export const ListDialog = () => {
    const [formStatus, setFormStatus] = useState<FormStatus>(FormStatus.UNSENT);
    const { state: { listOverlay }, dispatch } = useContext(AppContext);

    const onSubmitCompleted = (status: FormStatus) => {
        track.event("list your office space button (top)", {
            event_category: "Office Search funnel",
            event_label: "Hey Offices Homepage",
            value: "1",
        });
        setFormStatus(status);
    };

    const handleDialogClose = () => {
        setFormStatus(FormStatus.UNSENT);
        dispatch(toggleListOverlay());
    };

    return (
        <TheDialog open={listOverlay} aria-labelledby="Search Form" maxWidth="sm" fullWidth>
            <CloseButton id="listofficepopupx" onClick={handleDialogClose}>
                <CloseIcon fill="#fff" />
            </CloseButton>
            <DialogContent>
                {formStatus === FormStatus.UNSENT
                    && (
                        <Grid container direction="column" alignItems="center">
                            <Text size="1.5rem" sizeMd="2.125rem" textAlign="center" mt="1.5rem" bold>
                                Have an office space to rent out?
                            </Text>
                            <Text size="1.5rem" sizeMd="2.125rem" textAlign="center" mt="1.5rem" bold>
                                List it for free!
                            </Text>
                            <Instructions content="Pop your email below and we&apos;ll be in touch to find out more" />
                            <ListingForm onSubmitCompleted={onSubmitCompleted} />
                            <ProviderQuote />
                        </Grid>
                    )}
                {formStatus === FormStatus.SUCCESS
                    && (
                        <Grid container direction="column" alignItems="center">
                            <Text size="1.5rem" sizeMd="2.125rem" textAlign="center" mt="1.5rem" bold>
                                Message Sent!
                            </Text>
                            <Instructions content="One of our team will be in contact with you shortly." />
                            <Close id="listofficepopupclose" onClose={handleDialogClose} />
                        </Grid>
                    )}
            </DialogContent>
        </TheDialog>
    );
};
