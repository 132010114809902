import React from 'react';
import track from 'src/libs/analytics';
import { Formik, Form, Field } from 'formik';
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Typography,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Select } from 'formik-material-ui';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

import Button from 'src/shared/components/Button/Button';
import { LocationsSelect } from './LocationsSelect';

export type FormInput = {
    locations: string[];
    desks: string;
};

const initialValues = {
    locations: [],
    desks: "",
};

export const validationRequirements = Yup.object().shape({
    locations: Yup.array(Yup.string()).min(1, "Please select at least 1 location"),
    desks: Yup.string().required("Please select a desk range"),
});

const FieldInput = styled(InputLabel)(({ theme }) => ({
    fontSize: "0.9375rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    backgroundColor: "#fff",
}));

const FormItem = styled(Form)(() => ({
    margin: "2rem 0",
    maxWidth: "600px",
    width: "100%"
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    fontSize: "0.9375rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    "& .MuiSelect-select": {
        fontSize: "0.9375rem",
        fontWeight: "bold",
        color: theme.palette.primary.main,
    }
}));

const Instructions = styled(Typography)(() => ({
    marginTop: "10px",
    fontSize: "0.9rem"
}));

type SearchFormProps = {
    onClose: () => void;
}

export const SearchForm = ({ onClose }: SearchFormProps) => {
    const history = useHistory();

    const onSubmitForm = (values: FormInput, setSubmitting: (isSubmitting: boolean) => void) => {
        const desks = values.desks.split(values.desks === '100+' ? '+' : '-');
        const url = qs.stringify({
            locations: values.locations,
            officeSize: {
                option: 'desks',
                from: desks[0].trim(),
                to: desks[1].trim(),
            }
        });

        track.event("Find Office - Search Button", {
            event_category: "Office Search funnel",
            event_label: "Hey Offices Homepage",
            value: "1",
        });

        history.push(`/search-offices?${url}`);
        onClose();
        setSubmitting(false);
    };

    return (
        <Formik<FormInput>
            initialValues={initialValues}
            validationSchema={validationRequirements}
            onSubmit={(values, { setSubmitting }) => onSubmitForm(values, setSubmitting)}
        >
            {({ submitForm, isSubmitting, errors }) => (
                <FormItem>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} sm={6}>
                            <LocationsSelect />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container direction="column">
                                <Grid item style={{ maxWidth: "100%" }}>
                                    <StyledFormControl id="desksselect" fullWidth variant="outlined">
                                        <FieldInput>Desks Required</FieldInput>
                                        <Field
                                            component={Select}
                                            name="desks"
                                            error={!!errors.desks}
                                        >
                                            <StyledMenuItem value="1 - 4">1 - 4</StyledMenuItem>
                                            <StyledMenuItem value="5 - 9">5 - 9</StyledMenuItem>
                                            <StyledMenuItem value="10 - 20">10 - 20</StyledMenuItem>
                                            <StyledMenuItem value="21 - 41">21 - 41</StyledMenuItem>
                                            <StyledMenuItem value="41 - 60">41 - 60</StyledMenuItem>
                                            <StyledMenuItem value="61 - 100">61 - 100</StyledMenuItem>
                                            <StyledMenuItem value="100+">100+</StyledMenuItem>
                                        </Field>
                                        {Boolean(errors.desks) && <FormHelperText error>{errors.desks}</FormHelperText>}
                                    </StyledFormControl>
                                </Grid>
                                <Grid item>
                                    <Instructions>
                                        *Need space for say 15 people, select &quot;10-20&quot;. You get the gist!
                                    </Instructions>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {isSubmitting && <LinearProgress />}
                            <Button id="searchforofficespopup" size="large" onClick={submitForm} mt="3rem">
                                Search for Offices
                            </Button>
                        </Grid>
                    </Grid>
                </FormItem>
            )}
        </Formik>
    );
};
