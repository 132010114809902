import React, { useContext } from 'react';
import { Dialog, DialogContent, Grid, IconButton } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import { ReactComponent as CloseIcon } from 'src/assets/images/home/CloseIcon.svg';
import ResultsPageBlurLarge from 'src/assets/images/home/results-page-blur-large.png';
import ResultsPageBlurMedium from 'src/assets/images/home/results-page-blur-medium.png';
import ResultsPageBlurSmall from 'src/assets/images/home/results-page-blur-small.png';
import { AppContext } from 'src/store/store';
import { toggleSearchOverlay } from 'src/store/actions';
import Text from 'src/shared/components/Text/Text';
import { Instructions } from './components/Instructions';
import { SearchForm } from './components/SearchForm';

const TheDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiBackdrop-root': {
        backgroundImage: `url(${ResultsPageBlurSmall})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        filter: 'brightness(0.5)',
        [theme.breakpoints.up('md')]: {
            backgroundImage: `url(${ResultsPageBlurMedium})`,
        },
        [theme.breakpoints.up('lg')]: {
            backgroundImage: `url(${ResultsPageBlurLarge})`,
        },
    },
    "& .MuiDialog-paper": {
        overflow: "visible",
        border: "2px solid #F52727",
        borderRadius: 0,
    },
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '700px',
    },
}));

const CloseButton = styled(IconButton)({
    position: 'absolute',
    top: '0',
    right: '0',
    transform: 'translate(50%, -50%)',
});

export const SearchDialog = () => {
    const { state: { searchOverlay }, dispatch } = useContext(AppContext);

    const handleDialogClose = () => dispatch(toggleSearchOverlay());

    return (
        <TheDialog open={searchOverlay} aria-labelledby="Search Form" maxWidth="sm" fullWidth>
            <CloseButton id="searchforofficespopupx" onClick={handleDialogClose}>
                <CloseIcon fill="#fff" />
            </CloseButton>
            <DialogContent>
                <Grid container direction="column" alignItems="center">
                    <Text size="1.5rem" sizeMd="2.125rem" textAlign="center" mt="1.5rem" bold>
                        Find your new office space!
                    </Text>
                    <Instructions />
                    <SearchForm onClose={handleDialogClose} />
                </Grid>
            </DialogContent>
        </TheDialog>
    );
};
