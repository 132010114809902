import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    AppBar,
    Grid,
    IconButton,
    Link,
    Toolbar,
    Hidden,
    useMediaQuery,
} from '@material-ui/core';
import { styled, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import { ReactComponent as VideoIcon } from 'src/assets/images/video-icon.svg';
import Logo from 'src/components/Logo';
import Button from 'src/shared/components/Button/Button';
import { AppContext } from 'src/store/store';
import { shortlistProperties, toggleHowItWorksDialog } from 'src/store/actions';
import Favourites from './components/Favourites';
import { Menu } from './components/Menu';
import { MobileMenu } from '../Header/components/MoblieMenu';

const Header = styled(AppBar)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: '#ffffff',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    height: '4rem',
}));

const HowItWorksButton = styled(Button)({
    maxHeight: '32px',
    margin: '-6px 10px 0 0',
    paddingRight: '25px',
    paddingLeft: '25px',
    fontSize: '1rem',
    boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.75), 6px 6px 0 0  rgb(51 68 254)',
    '&:hover': {
        backgroundColor: 'rgb(51 68 254)',
    }
});

const HowItWorksInvertedButton = styled(Button)({
    paddingRight: '40px',
    paddingLeft: '40px',
    color: '#fff',
    borderColor: '#fff',
    fontSize: '1.25rem',
    boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.75), 6px 6px 0 0 rgb(51, 68, 254)',
    '&:hover': {
        color: 'black',
        backgroundColor: 'rgb(51, 68, 254)',
        '& svg': {
            fill: 'black',
        },
    },
});

function TopBar() {
    const { state: { shortlistedProperties }, dispatch } = useContext(AppContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const matchesSmScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    useEffect(() => {
        const shortlistedPropertiesStorage = localStorage.getItem('shortlistedProperties');
        dispatch(shortlistProperties(JSON.parse(shortlistedPropertiesStorage || '[]')));
    }, [dispatch]);

    useEffect(() => {
        localStorage.setItem('shortlistedProperties', JSON.stringify(shortlistedProperties));
    }, [shortlistedProperties]);

    const openHowItWorksDialog = () => {
        setMenuOpen(false);
        dispatch(toggleHowItWorksDialog());
    };

    const handleMenuToggle = useCallback(() => setMenuOpen((state) => !state), []);

    return (
        <Header>
            <StyledToolbar>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Logo
                            action={(
                                <Link
                                    href="mailto:hey@heyoffices.com"
                                    underline="always"
                                    target="_blank"
                                    variant="body1"
                                >
                                    hey@heyoffices.com
                                </Link>
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" spacing={matchesSmScreen ? 0 : 2}>
                            <Hidden smDown>
                                <Grid item>
                                    <HowItWorksButton size="small" onClick={openHowItWorksDialog} startIcon={<VideoIcon />}>
                                        How it works
                                    </HowItWorksButton>
                                </Grid>
                            </Hidden>
                            <Grid item>
                                <Favourites />
                            </Grid>
                            <Grid item>
                                <IconButton onClick={handleMenuToggle} edge="end">
                                    <MenuIcon fontSize="large" color="primary" />
                                </IconButton>
                            </Grid>
                            <Hidden smDown>
                                <Menu onClose={handleMenuToggle} open={menuOpen} />
                            </Hidden>
                            <Hidden mdUp>
                                <MobileMenu
                                    open={menuOpen}
                                    onClose={handleMenuToggle}
                                    button={(
                                        <HowItWorksInvertedButton
                                            size="medium"
                                            onClick={openHowItWorksDialog}
                                            startIcon={<VideoIcon fill="white" />}
                                            mt="2rem"
                                        >
                                            How it works
                                        </HowItWorksInvertedButton>
                                    )}
                                />
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledToolbar>
        </Header>
    );
}

export default TopBar;
